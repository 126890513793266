import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  DialogContentText,
  Button,
  List,
  ListItem,
  CircularProgress,
  Box,
  CardMedia,
  Grid,
  Link,
} from "@mui/material";
import MediaQuery from "react-responsive";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db, getToken, uploadImage } from "../firebase";
import Atras from "./atras";
import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import CopyToClipboardComponent from "./copy";
import {
  AttachMoney as AttachMoneyIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import BottomToolbar from "./BottomToolbar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const EditOffers = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (offerId) => {
    setSelectedOfferId(offerId);
    setOpen(true);
  };

  const handleDelete = async (offerId) => {
    try {
      const offerRef = doc(db, "offers", offerId);
      await deleteDoc(offerRef);
    } catch (error) {
      console.error("Error al eliminar la oferta:", error);
    }
  };

  const handleSubmit = async () => {
    if (!product || !description || !offer || !url) {
      alert("Por favor, rellena todos los campos obligatorios");
      return;
    }

    const offersRef = doc(db, "offers", selectedOfferId);
    const updateData = {
      product,
      description,
      offer,
      url,
      cupon,
      images: formData.images,
    };
    try {
      await updateDoc(offersRef, updateData);
      handleClose();
    } catch (error) {
      console.error("Error updating offer:", error);
      alert(
        "Hubo un error al actualizar la oferta. Por favor, inténtalo de nuevo."
      );
    }
  };

  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [offer, setOffer] = useState("");
  const [url, setUrl] = useState("");
  const [cupon, setCupon] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [imageUrl, setImageUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({
    product: "",
    description: "",
    offer: "",
    url: "",
    cupon: "",
    images: [],
  });

  const handleProduct = (event) => {
    setProduct(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleOffer = (event) => {
    setOffer(event.target.value);
  };

  const handleUrl = (event) => {
    setUrl(event.target.value);
  };

  const handleCupon = (event) => {
    setCupon(event.target.value);
  };

  const [offers, setOffers] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = offers.filter((offer) =>
      offer.product.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, offers]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndFetchOffers = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();

        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        setLoading(true);
        const { uid } = currentUser;
        const q = query(collection(db, "offers"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const offersData = snapshot.docs.map((doc) => {
            const data = doc.data();
            if (typeof data.product !== "string") {
              console.error(
                "Unexpected data type for name field:",
                data.product
              );
              return { ...data, product: "", image: "", imageUrls: [] };
            }
            let imageUrls = [];
            if (data.images && Array.isArray(data.images)) {
              imageUrls = data.images;
            } else if (data.images && typeof data.images === "string") {
              imageUrls = [data.images];
            }

            console.log("Processed imageUrls:", imageUrls); // Debugging log

            return {
              ...data,
              id: doc.id,
              createdAt: data.createdAt.toDate(),
              imageUrls,
            };
          });
          setOffers(offersData);
          setLoading(false);
          console.log("Ofertas obtenidas:", offersData);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener ofertas:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchOffers();
  }, [navigate]);

  const handleImageUpload = async (event) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    setLoading(true);

    try {
      const imageUrls = [];

      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: prevFormData.images.filter((_, i) => i !== index),
    }));
  };

  const getDomainFromUrl = (url) => {
    // Si la URL no es válida, devuelve la URL original
    if (!url) {
      return url;
    }

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    const domain = new URL(url).hostname;

    // Encuentra el punto "." antes de la extensión
    const dotIndex = domain.lastIndexOf(".");

    // Si no se encuentra un punto, devuelve el dominio completo
    if (dotIndex === -1) {
      return domain;
    }

    // Extrae el dominio hasta el punto "."
    const shortenedDomain = domain.substring(0, dotIndex);

    // Si el dominio es demasiado largo, lo recorta y agrega "..."
    if (shortenedDomain.length > 15) {
      return shortenedDomain.slice(0, 15) + "...";
    }

    // Devuelve el dominio recortado
    return shortenedDomain;
  };

  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "black",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      marginTop: "20px",
      justifyContent: "space-around",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "98%",
      border: "0.5px solid #ccc",
      margin: "10px auto",
      boxShadow: "0px 0px 5px 0px black",
    },
    typography: {
      marginBottom: "15px",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      marginRight: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "0px",
    },
    title: {
      fontSize: "20px",
      marginBottom: "15px",
    },
    offer: {
      color: "dodgerblue",
      fontSize: "18px",
      marginBottom: "15px",
    },
    cupon: {
      color: "lightseagreen",
      padding: "3px",
      borderRadius: "3px",
      backgroundColor: "lightgrey",
      fontSize: "20px",
      marginBottom: "15px",
    },
    price: {
      color: "lightseagreen",
      marginBottom: "15px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "15px",
      marginTop: "15px",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },

    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
    imagePreview: {
      width: "100%",
      height: "200px",
      objectFit: "cover",
      borderRadius: "10px",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicaciones
            </Typography>
          </Container>
        </Toolbar>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "space-around",
            color: "black",
            borderTop: "1.5px solid white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editOffers" sx={{ color: "white" }}>
              <AttachMoneyIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar ofertas
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editReference" sx={{ color: "white" }}>
              <AccessTimeIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar referencias
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editEvents" sx={{ color: "white" }}>
              <EventIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar eventos
            </Typography>
          </div>
        </Toolbar>
        <Toolbar sx={styles.search}>
          <input
            type="text"
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
            style={styles.inputS}
            placeholder="Buscar nombre de producto..."
          />
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 33, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {resultado.length > 0 ? (
                <List>
                  {resultado.map((selectedOffer) => (
                    <ListItem key={selectedOffer.id} style={styles.listItem}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        {/* Columna izquierda para imágenes */}
                        <Box
                          sx={{
                            width: { xs: "100%", md: "30%" },
                            mr: { xs: 0, md: 2 },
                            mb: { xs: 2, md: 0 },
                          }}
                        >
                          <Box style={styles.contUser}>
                            <CardMedia
                              component="img"
                              image={selectedOffer.image}
                              title="Perfil"
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                marginRight: 16,
                                border: "2.5px solid #312783",
                              }}
                            />
                            <Link href={`/perfil/${selectedOffer.uid}`}>
                              <Typography style={styles.name}>
                                {selectedOffer.name}
                              </Typography>
                            </Link>
                          </Box>
                          {selectedOffer.imageUrls &&
                          selectedOffer.imageUrls.length > 0 ? (
                            <>
                              <Box style={styles.imageContainer}>
                                <CardMedia
                                  component="img"
                                  image={selectedOffer.images[0]}
                                  alt={`Imagen principal de ${selectedOffer.name}`}
                                  style={styles.mainImage}
                                />
                                {selectedOffer.images.length > 1 && (
                                  <Box
                                    style={styles.additionalImagesOverlay}
                                    onClick={() =>
                                      handleOpenImageModal(selectedOffer.images)
                                    }
                                  >
                                    <Typography
                                      style={styles.additionalImagesCount}
                                    >
                                      +{selectedOffer.images.length - 1}
                                    </Typography>
                                    <Typography>Ver más</Typography>
                                  </Box>
                                )}
                              </Box>
                            </>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>
                                No hay imágenes disponibles
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        {/* Columna derecha para el contenido */}
                        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                          <Typography style={styles.title}>
                            {selectedOffer.product}
                          </Typography>
                          <Typography style={styles.description}>
                            {selectedOffer.description}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            href={`http://${selectedOffer.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ width: "100%", mt: 1, mb: 1 }}
                          >
                            Comprar en {getDomainFromUrl(selectedOffer.url)}
                          </Button>
                          <Typography style={styles.offer}>
                            Descuento: {selectedOffer.offer}%
                          </Typography>
                          <CopyToClipboardComponent
                            textToCopy={selectedOffer.cupon}
                          />
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => handleEditClick(selectedOffer.id)}
                          >
                            <Typography>Editar</Typography>
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ mt: 2 }}
                            fullWidth
                            onClick={() => handleDelete(selectedOffer.id)} // Llamamos a la función de eliminación con el ID correspondiente
                          >
                            <Typography>Eliminar</Typography>
                          </Button>
                          <Typography style={styles.created}>
                            Fecha de publicación:{" "}
                            {formatDate(selectedOffer.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List>
                  {offers.map((offers) => (
                    <ListItem key={offers.id} style={styles.listItem}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        {/* Columna izquierda para imágenes */}
                        <Box
                          sx={{
                            width: { xs: "100%", md: "30%" },
                            mr: { xs: 0, md: 2 },
                            mb: { xs: 2, md: 0 },
                          }}
                        >
                          <Box style={styles.contUser}>
                            <CardMedia
                              component="img"
                              image={offers.image}
                              title="Perfil"
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                marginRight: 16,
                                border: "2.5px solid #312783",
                              }}
                            />
                            <Typography style={styles.name}>
                              {offers.name}
                            </Typography>
                          </Box>
                          {offers.imageUrls && offers.imageUrls.length > 0 ? (
                            <>
                              <Box style={styles.imageContainer}>
                                <CardMedia
                                  component="img"
                                  image={offers.images[0]}
                                  alt={`Imagen principal de ${offers.name}`}
                                  style={styles.mainImage}
                                />
                                {offers.images.length > 1 && (
                                  <Box
                                    style={styles.additionalImagesOverlay}
                                    onClick={() =>
                                      handleOpenImageModal(offers.images)
                                    }
                                  >
                                    <Typography
                                      style={styles.additionalImagesCount}
                                    >
                                      +{offers.images.length - 1}
                                    </Typography>
                                    <Typography>Ver más</Typography>
                                  </Box>
                                )}
                              </Box>
                            </>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>
                                No hay imágenes disponibles
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        {/* Columna derecha para el contenido */}
                        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                          <Typography style={styles.title}>
                            {offers.product}
                          </Typography>
                          <Typography style={styles.description}>
                            {offers.description}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            href={`http://${offers.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ width: "100%", mt: 1, mb: 1 }}
                          >
                            Comprar en {getDomainFromUrl(offers.url)}
                          </Button>
                          <Typography style={styles.offer}>
                            Descuento: {offers.offer}%
                          </Typography>
                          <CopyToClipboardComponent textToCopy={offers.cupon} />
                          <Typography style={styles.created}>
                            Fecha de publicación: {formatDate(offers.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose} boxSizing>
        <DialogTitle>Editar Descuento</DialogTitle>
        <DialogContent>
          <MediaQuery minWidth={1224}>
            <TextField
              required
              autoFocus
              margin="dense"
              id="product"
              label="Nombre del producto"
              type="text"
              fullWidth
              name="product"
              value={product}
              onChange={handleProduct}
            />
            <TextField
              required
              autoFocus
              margin="dense"
              id="description"
              label="Descripción del producto"
              type="text"
              multiline
              rows={2}
              fullWidth
              name="description"
              value={description}
              onChange={handleDescription}
            />
            <TextField
              required
              margin="dense"
              id="offer"
              label="Descuento"
              type="text"
              fullWidth
              name="offer"
              value={offer}
              onChange={handleOffer}
            />
            <TextField
              required
              margin="dense"
              id="url"
              label="URL"
              type="text"
              fullWidth
              name="url"
              value={url}
              onChange={handleUrl}
            />
            <TextField
              required
              margin="dense"
              id="cupon"
              label="Cupón"
              type="text"
              fullWidth
              name="cupon"
              value={cupon}
              onChange={handleCupon}
            />
            <label htmlFor="upload-button" style={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                component="div" // Cambiado a "div" para evitar el enfoque del botón
                sx={styles.uploadButton}
                disabled={loading}
              >
                <AddPhotoAlternateIcon sx={{ color: "black" }} />
                Subir Imagenes del producto
                {loading && (
                  <CircularProgress
                    size={40}
                    thickness={4}
                    sx={styles.progress}
                  />
                )}
              </Button>
            </label>
            <input
              required
              type="file"
              accept="image/*"
              onClick={(event) => (event.target.value = null)}
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="upload-button"
              multiple // Add the multiple attribute
            />
            <input
              required
              type="file"
              accept="image/*"
              onClick={(event) => (event.target.value = null)}
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="upload-button"
            />
            <Grid container sx={{ mt: 2 }}>
              {formData.images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      border: "1.5px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Imagen de la propiedad"
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </MediaQuery>
          <MediaQuery maxWidth={1223}>
            <TextField
              required
              autoFocus
              margin="dense"
              id="product"
              label="Nombre del producto"
              type="text"
              fullWidth
              name="product"
              value={product}
              onChange={handleProduct}
            />
            <TextField
              required
              autoFocus
              margin="dense"
              id="description"
              label="Descripción del producto"
              type="text"
              multiline
              rows={2}
              fullWidth
              name="description"
              value={description}
              onChange={handleDescription}
            />
            <TextField
              required
              margin="dense"
              id="offer"
              label="Descuento"
              type="text"
              fullWidth
              name="offer"
              value={offer}
              onChange={handleOffer}
            />
            <TextField
              required
              margin="dense"
              id="url"
              label="URL"
              type="text"
              fullWidth
              name="url"
              value={url}
              onChange={handleUrl}
            />
            <TextField
              required
              margin="dense"
              id="cupon"
              label="Cupón"
              type="text"
              fullWidth
              name="cupon"
              value={cupon}
              onChange={handleCupon}
            />
            <label htmlFor="upload-button" style={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                component="div" // Cambiado a "div" para evitar el enfoque del botón
                sx={styles.uploadButton}
                disabled={loading}
              >
                <AddPhotoAlternateIcon sx={{ color: "black" }} />
                Subir Imagenes del producto
                {loading && (
                  <CircularProgress
                    size={40}
                    thickness={4}
                    sx={styles.progress}
                  />
                )}
              </Button>
            </label>
            <input
              required
              type="file"
              accept="image/*"
              onClick={(event) => (event.target.value = null)}
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="upload-button"
              multiple // Add the multiple attribute
            />
            <input
              required
              type="file"
              accept="image/*"
              onClick={(event) => (event.target.value = null)}
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="upload-button"
            />
            <Grid container sx={{ mt: 2 }}>
              {formData.images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      border: "1.5px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Imagen de la propiedad"
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </MediaQuery>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        fullScreen // Make the modal full screen
      >
        <DialogTitle id="image-modal-title">
          Imágenes del producto
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="image-modal-description">
            {openImageModal && (
              <Box style={styles.imageModalContent}>
                {modalImages && modalImages.length > 0 ? (
                  <CardMedia
                    component="img"
                    image={modalImages[currentImageIndex]}
                    alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                    style={styles.imageModalImage}
                  />
                ) : (
                  <Typography>No hay imágenes disponibles</Typography>
                )}
                <Box style={styles.imageModalNavigation}>
                  <IconButton onClick={handlePreviousImage}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton onClick={handleNextImage}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <BottomToolbar />
    </Container>
  );
};

export default EditOffers;
