import { useEffect } from "react";
import { auth, db, saveTokenToFirestore } from "../firebase";
import { getToken } from "firebase/messaging";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteField,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  addDoc,
  where,
  serverTimestamp,
} from "firebase/firestore";

export const requestNotificationPermission = async (messaging) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BN-njmyixSEuzLm64hdgPik9iz4vDxG7XxtsRJD6WHNply94JZpXtmkaHOeGFwUMqDZMOPEnrt-eJTBBZZkIbbQ",
      });
      console.log("FCM token:", token);

      if (token) {
        await saveTokenToFirestore(token);
      } else {
        console.error("No se pudo obtener el token FCM");
      }
    } else {
      console.error("Permiso de notificaciones denegado");
    }
  } catch (error) {
    console.error("Error al obtener el token FCM:", error);
  }
};

export const getCountryFromCoordinates = async (latitude, longitude) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBG6H69JDv-9Y0O2XJXhvHgdOIJiuGzRt0`
    );
    const data = await response.json();
    const country = data.results[0].address_components.find((component) =>
      component.types.includes("country")
    ).long_name;
    console.log(country);
    return country;
  } catch (error) {
    console.error("Error al obtener el país:", error);
    return null;
  }
};

export const formatDate = (date) => {
  if (date instanceof Date) {
    return date.toLocaleDateString();
  } else if (date && date.toDate instanceof Function) {
    return date.toDate().toLocaleDateString();
  }
  return "";
};

export const getDomainFromUrl = (url) => {
  if (!url) {
    return url;
  }

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }
  const domain = new URL(url).hostname;
  const dotIndex = domain.lastIndexOf(".");

  if (dotIndex === -1) {
    return domain;
  }

  const shortenedDomain = domain.substring(0, dotIndex);

  if (shortenedDomain.length > 15) {
    return shortenedDomain.slice(0, 15) + "...";
  }

  return shortenedDomain;
};

export const handleMeSirve = async (
  offerId,
  userAuthenticated,
  userId,
  setOffers,
  setUserLikes
) => {
  if (!userAuthenticated) {
    console.log("Debes iniciar sesión para usar esta función");
    return;
  }

  const offerRef = doc(db, "offers", offerId);
  const userLikesRef = doc(db, "userLikes", userId);

  try {
    const offerDoc = await getDoc(offerRef);
    const offerData = offerDoc.data();
    const currentLikes = offerData.likes || [];

    if (currentLikes.includes(userId)) {
      await updateDoc(offerRef, {
        likes: arrayRemove(userId),
        helpedCount: increment(-1),
      });
      await updateDoc(userLikesRef, {
        [offerId]: deleteField(),
      });
      console.log("'Me sirve' removido");
    } else {
      await updateDoc(offerRef, {
        likes: arrayUnion(userId),
        helpedCount: increment(1),
      });
      await setDoc(userLikesRef, { [offerId]: true }, { merge: true });
      console.log("'Me sirve' agregado");
    }

    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.id === offerId
          ? {
              ...offer,
              helpedCount:
                offer.helpedCount + (currentLikes.includes(userId) ? -1 : 1),
            }
          : offer
      )
    );
    setUserLikes((prevLikes) => ({
      ...prevLikes,
      [offerId]: !prevLikes[offerId],
    }));
  } catch (error) {
    console.error("Error al actualizar 'Me sirve':", error);
  }
};

export const handleFollow = async (
  companyId,
  uid,
  following,
  setFollowing,
  setLoading
) => {
  try {
    setLoading(true);
    const userFollowingRef = doc(db, "userFollowing", uid);
    const companyFollowersRef = doc(db, "companyFollowers", companyId);

    const isFollowing = following.includes(companyId);

    if (isFollowing) {
      await updateDoc(userFollowingRef, {
        [companyId]: deleteField(),
      });
      await updateDoc(companyFollowersRef, {
        [uid]: deleteField(),
      });
      setFollowing((prev) => prev.filter((id) => id !== companyId));
    } else {
      await setDoc(userFollowingRef, { [companyId]: true }, { merge: true });
      await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
      setFollowing((prev) => [...prev, companyId]);
    }
    setLoading(false);
  } catch (error) {
    console.error("Error al manejar el seguimiento de la empresa:", error);
    setLoading(false);
  }
};

export const filterOffersByCountry = async (
  coordinates,
  offers,
  setFilteredOffers
) => {
  if (coordinates) {
    const country = await getCountryFromCoordinates(
      coordinates.latitude,
      coordinates.longitude
    );

    if (country) {
      const filtered = offers.filter((offer) => offer.country === country);
      setFilteredOffers(filtered);
    } else {
      setFilteredOffers(offers);
    }
  }
};

export const fetchUserLikes = async (userAuthenticated, setUserLikes) => {
  if (userAuthenticated && auth.currentUser) {
    const userLikesRef = doc(db, "userLikes", auth.currentUser.uid);
    const userLikesDoc = await getDoc(userLikesRef);
    if (userLikesDoc.exists()) {
      setUserLikes(userLikesDoc.data());
    }
  }
};

export const fetchFollowing = async (setFollowing, setLoading) => {
  try {
    const { uid } = auth.currentUser || {};
    if (uid) {
      const userFollowingRef = doc(db, "userFollowing", uid);
      const userFollowingDoc = await getDoc(userFollowingRef);

      if (userFollowingDoc.exists()) {
        const followingData = userFollowingDoc.data();
        const followingIds = Object.keys(followingData);
        setFollowing(followingIds);
      } else {
        setFollowing([]);
      }
    }
    setLoading(false);
  } catch (error) {
    console.error("Error al obtener las empresas seguidas:", error);
    setLoading(false);
  }
};

export const fetchOffersAndStats = (setLoading, setOffers) => {
  try {
    setLoading(true);
    const offersCollectionRef = collection(db, "offers");

    const unsubscribeOffers = onSnapshot(
      query(offersCollectionRef, orderBy("createdAt", "desc")),
      (snapshot) => {
        const offersData = snapshot.docs.map((doc) => {
          const data = doc.data();
          let imageUrls = [];
          if (data.images && Array.isArray(data.images)) {
            imageUrls = data.images;
          } else if (data.images && typeof data.images === "string") {
            imageUrls = [data.images];
          }

          return {
            id: doc.id,
            ...data,
            uid: doc.data().uid,
            uide: doc.data().uide,
            imageUrls,
            helpedCount: data.helpedCount || 0,
          };
        });
        setLoading(false);
        setOffers(offersData);
        console.log("Ofertas obtenidas:", offersData);
      },
      (error) => {
        console.error("Error fetching offers:", error);
        setLoading(false);
      }
    );

    return unsubscribeOffers;
  } catch (error) {
    console.error("Error al obtener ofertas o estadísticas:", error);
    setLoading(false);
    return () => {}; // Return an empty function if there's an error
  }
};

export const useListenForNewPostsAndEvents = () => {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently authenticated");
      return;
    }

    let unsubscribeOffers;
    let unsubscribeEvents;

    const setupListeners = async () => {
      try {
        // Consulta las empresas seguidas por el usuario
        const followedCompaniesQuery = query(
          collection(db, "followedCompanies"),
          where("userId", "==", user.uid)
        );

        const followedCompaniesSnapshot = await getDocs(followedCompaniesQuery);
        const followedCompanies = followedCompaniesSnapshot.docs.map(
          (doc) => doc.id
        );

        if (followedCompanies.length === 0) {
          console.log("User is not following any companies");
          return;
        }

        console.log("Followed companies: ", followedCompanies);

        // Función para manejar nuevos posts/eventos
        const handleNewItem = async (change, type) => {
          if (change.type === "added") {
            const newItem = change.doc.data();
            console.log(`New ${type}: `, newItem);
            const itemUserId = newItem.userId || newItem.uid || newItem.uide;
            if (!itemUserId) {
              console.error(`${type} does not have a valid userId`, newItem);
              return;
            }

            if (followedCompanies.includes(itemUserId)) {
              // Consultar si ya existe una notificación para este item
              const notificationQuery = query(
                collection(db, "notifications"),
                where("userId", "==", user.uid),
                where("postId", "==", change.doc.id),
                where("type", "==", type)
              );

              const querySnapshot = await getDocs(notificationQuery);
              if (querySnapshot.empty) {
                // Añadir nueva notificación si no existe
                try {
                  await addDoc(collection(db, "notifications"), {
                    userId: user.uid,
                    companyId: itemUserId,
                    postId: change.doc.id,
                    message: `${
                      newItem.displayName || newItem.name
                    } ha publicado ${
                      type === "offer" ? "una nueva oferta" : "un nuevo evento"
                    }`,
                    type: type,
                    read: false,
                    timestamp: serverTimestamp(),
                  });
                  console.log(`${type} notification added successfully`);
                } catch (error) {
                  console.error(`Error adding ${type} notification: `, error);
                }
              } else {
                console.log(
                  `${type} notification already exists for this post`
                );
              }
            }
          }
        };

        // Escuchar nuevos posts en "offers"
        const offersQuery = collection(db, "offers");
        unsubscribeOffers = onSnapshot(offersQuery, (snapshot) => {
          snapshot
            .docChanges()
            .forEach((change) => handleNewItem(change, "offer"));
        });

        // Escuchar nuevos eventos en "events"
        const eventsQuery = collection(db, "events");
        unsubscribeEvents = onSnapshot(eventsQuery, (snapshot) => {
          snapshot
            .docChanges()
            .forEach((change) => handleNewItem(change, "event"));
        });
      } catch (error) {
        console.error("Error setting up listeners: ", error);
      }
    };

    setupListeners();

    // Cleanup function
    return () => {
      if (unsubscribeOffers) unsubscribeOffers();
      if (unsubscribeEvents) unsubscribeEvents();
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount
};

export const fetchQuestionsAndAnswers = (offerId, setQuestions) => {
  const q = query(
    collection(db, "questions"),
    where("offerId", "==", offerId),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedQuestions = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setQuestions(fetchedQuestions);
  });

  return unsubscribe;
};

export const postQuestion = async (offerId, userId, questionText) => {
  try {
    await addDoc(collection(db, "questions"), {
      offerId,
      userId,
      question: questionText,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error al publicar la pregunta: ", error);
  }
};

export const postAnswer = async (questionId, userId, answerText, offerId) => {
  try {
    // Asumiendo que tienes una colección 'answers' para almacenar respuestas
    await addDoc(collection(db, "answers"), {
      questionId,
      userId,
      offerId,
      answer: answerText,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error al publicar la respuesta: ", error);
  }
};

export const fetchAnswers = (offerId, setAnswers) => {
  const q = query(
    collection(db, "answers"),
    where("offerId", "==", offerId),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedAnswers = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Respuestas obtenidas:", fetchedAnswers);
    setAnswers(fetchedAnswers);
  });

  return unsubscribe;
};


export const fetchQuestionsAndAnswersWithUserData = (
  offerId,
  setQuestionsAndAnswers
) => {
  const q = query(
    collection(db, "questions"),
    where("offerId", "==", offerId),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const questionsAndAnswers = [];
    for (const questionDoc of snapshot.docs) {
      const questionData = questionDoc.data();

      // Fetch user data for the question
      const userDocRef = doc(db, "users", questionData.userId);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      // Fetch answers for the question
      const answersQuery = query(
        collection(db, "answers"),
        where("questionId", "==", questionDoc.id)
      );
      const answersSnapshot = await getDocs(answersQuery);
      const answers = await Promise.all(
        answersSnapshot.docs.map(async (answerDoc) => {
          const answerData = answerDoc.data();

          // Fetch user data for the answer
          const answerUserDocRef = doc(db, "users", answerData.userId);
          const answerUserDocSnap = await getDoc(answerUserDocRef);
          const answerUserData = answerUserDocSnap.data();

          return {
            id: answerDoc.id,
            ...answerData,
            displayName: answerUserData.displayName,
            profileImageUrl: answerUserData.profileImageUrl,
          };
        })
      );

      questionsAndAnswers.push({
        id: questionDoc.id,
        ...questionData,
        displayName: userData.displayName,
        profileImageUrl: userData.profileImageUrl,
        answers: answers,
      });
    }

    setQuestionsAndAnswers(questionsAndAnswers);
  });

  return unsubscribe;
};
