import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  CircularProgress,
  DialogContentText,
  TextField,
  Divider,
  Paper,
  Collapse,
  Avatar,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Link } from 'react-router-dom';
import {
  AccountCircle as AccountCircleIcon,
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CopyToClipboardComponent from "./copy";
import { isLoggedIn } from "../session";
import { auth, db, messaging } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { useWatchPosition } from "react-use-geolocation";
import {
  requestNotificationPermission,
  formatDate,
  getDomainFromUrl,
  handleMeSirve,
  handleFollow,
  fetchOffersAndStats,
  filterOffersByCountry,
  fetchUserLikes,
  fetchFollowing,
  useListenForNewPostsAndEvents,
  fetchQuestionsAndAnswers,
  postQuestion,
  postAnswer,
  fetchAnswers,
  fetchQuestionsAndAnswersWithUserData,
} from "./homeUtils";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userLikes, setUserLikes] = useState({});
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);
  const [questions, setQuestions] = useState({});
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState({});
  const answerInputRef = useRef({});
  const [answers, setAnswers] = useState({});
  const [showMoreQuestions, setShowMoreQuestions] = useState({});
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { loaded, coordinates } = useWatchPosition();

  useEffect(() => {
    const fetchIcon = async () => {
      const result = await getIconForUserType();
      setIcon(result);
    };

    fetchIcon();
  });

  const getIconForUserType = async () => {
    const { uid } = auth.currentUser || {};
    if (!uid) {
      return null;
    }
    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const userType = userData.userType;

      if (userType === "empresa") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/publishOffer">
              <AddCircleIcon sx={{ color: "#312783" }} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Publicar
            </Typography>
          </div>
        );
      } else {
        return null;
      }
    }
  };

  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const checkAuthAndRequestPermission = async () => {
      const loggedIn = await isLoggedIn();
      setUserAuthenticated(loggedIn);
      setLoading(false);

      if (loggedIn) {
        await requestNotificationPermission(messaging);
      }
    };

    checkAuthAndRequestPermission();
  }, []);

  useEffect(() => {
    const unsubscribeFunc = subscribeToNotifications(setHasNewNotifications);

    return () => {
      if (typeof unsubscribeFunc === "function") {
        unsubscribeFunc();
      }
    };
  }, []);

  useEffect(() => {
    if (userAuthenticated) {
      fetchUserLikes(userAuthenticated, setUserLikes);
    }
  }, [userAuthenticated]);

  useEffect(() => {
    const unsubscribe = fetchOffersAndStats(setLoading, setOffers);
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (loaded && coordinates) {
      filterOffersByCountry(coordinates, offers, setFilteredOffers);
    }
  }, [loaded, coordinates, offers]);

  useEffect(() => {
    fetchFollowing(setFollowing, setLoading);
  }, []);

  useListenForNewPostsAndEvents();

  const handleMeSirveClick = (offerId) => {
    handleMeSirve(
      offerId,
      userAuthenticated,
      auth.currentUser?.uid,
      setOffers,
      setUserLikes
    );
  };

  const handleFollowClick = (companyId) => {
    handleFollow(
      companyId,
      auth.currentUser?.uid,
      following,
      setFollowing,
      setLoading
    );
  };
  const buscar = useCallback(() => {
    if (!Array.isArray(offers)) {
      console.error("events no es un array:", offers);
      return;
    }
    const valorBusqueda = busqueda.toLowerCase().trim();
    const resultadosFiltrados = offers.filter(
      (offer) =>
        offer.name.toLowerCase().includes(valorBusqueda) ||
        (offer.title && offer.title.toLowerCase().includes(valorBusqueda))
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, offers]);

  useEffect(() => {
    buscar();
  }, [busqueda, offers, buscar]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  const isCompanyFollowed = (companyId) => following.includes(companyId);

  useEffect(() => {
    if (offers.length > 0) {
      offers.forEach((offer) => {
        fetchQuestionsAndAnswers(offer.id, (fetchedQuestions) => {
          setQuestions((prevQuestions) => ({
            ...prevQuestions,
            [offer.id]: fetchedQuestions,
          }));
        });
        // Obtener las respuestas
        fetchAnswers(offer.id, (fetchedAnswers) => {
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [offer.id]: fetchedAnswers,
          }));
        });
      });
    }
  }, [offers]);

  const handleQuestionSubmit = async (offerId) => {
    if (newQuestion.trim() !== "") {
      await postQuestion(offerId, auth.currentUser?.uid, newQuestion);
      setNewQuestion("");
    }
  };

  const handleAnswerSubmit = async (questionId, offerId) => {
    if (
      newAnswer[questionId]?.trim() !== "" &&
      !answers[offerId]?.some((answer) => answer.questionId === questionId)
    ) {
      await postAnswer(
        questionId,
        auth.currentUser?.uid,
        newAnswer[questionId],
        offerId
      );
      setNewAnswer((prevAnswers) => ({ ...prevAnswers, [questionId]: "" }));
    }
  };

  useEffect(() => {
    // Itera sobre las respuestas para cada oferta
    Object.entries(answers).forEach(([offerId, offerAnswers]) => {
      // Itera sobre las preguntas para la oferta actual
      questions[offerId]?.forEach((question) => {
        // Verifica si la pregunta ya tiene una respuesta
        const hasAnswer = offerAnswers.some(
          (answer) => answer.questionId === question.id
        );

        // Si la pregunta ya tiene una respuesta, limpia el estado de la nueva respuesta
        if (hasAnswer) {
          setNewAnswer((prevAnswers) => ({
            ...prevAnswers,
            [question.id]: "",
          }));
        }
      });
    });
  }, [answers, questions]);

  useEffect(() => {
    if (offers.length > 0) {
      offers.forEach((offer) => {
        fetchQuestionsAndAnswersWithUserData(offer.id, (fetchedData) => {
          setQuestionsAndAnswers((prevData) => ({
            ...prevData,
            [offer.id]: fetchedData,
          }));
        });
      });
    }
  }, [offers]);

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "100%",
      border: "0.5px solid #ccc",
      margin: "25px auto",
      boxShadow: "0px 0px 5px 0px black",
      padding: "16px",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "auto",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "15px",
      marginTop: "15px",
      textDecoration: "underline",
    },
    title: {
      fontSize: "20px",
      marginBottom: "15px",
    },
    offer: {
      color: "dodgerblue",
      fontSize: "18px",
      marginBottom: "15px",
    },
    url: {
      marginBottom: "15px",
    },
    description: {
      marginBottom: "15px",
      fontWeight: "300",
    },
    cupon: {
      color: "lightseagreen",
      padding: "3px",
      borderRadius: "3px",
      backgroundColor: "lightgrey",
      fontSize: "20px",
      marginBottom: "15px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "15px",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "16px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },

    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      justifyContent: "space-around",
    },
  };

  const renderOfferList = (offersList) => {
    return (
      <Container>
        <List>
          {offersList.map((offer) => (
            <ListItem key={offer.id} style={styles.listItem}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                {/* Left column for images */}
                <Box
                  sx={{
                    width: { xs: "100%", md: "30%" },
                    mr: { xs: 0, md: 2 },
                    mb: { xs: 2, md: 0 },
                  }}
                >
                  <Box style={styles.contUser}>
                    <CardMedia
                      component="img"
                      image={offer.image}
                      title="Perfil"
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        marginRight: 16,
                        border: "2.5px solid #312783",
                      }}
                    />
                    <Link to={`/perfil/${offer.uid}`}>
                      <Typography style={styles.name}>{offer.name}</Typography>
                    </Link>
                  </Box>
                  {offer.imageUrls && offer.imageUrls.length > 0 ? (
                    <Box style={styles.imageContainer}>
                      <CardMedia
                        component="img"
                        image={offer.images[0]}
                        alt={`Imagen principal de ${offer.name}`}
                        style={styles.mainImage}
                      />
                      {offer.images.length > 1 && (
                        <Box
                          style={styles.additionalImagesOverlay}
                          onClick={() => handleOpenImageModal(offer.images)}
                        >
                          <Typography style={styles.additionalImagesCount}>
                            +{offer.images.length - 1}
                          </Typography>
                          <Typography>Ver más</Typography>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box style={styles.noImage}>
                      <Typography>No hay imágenes disponibles</Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <IconButton
                      onClick={() => handleMeSirveClick(offer.id)}
                      color={userLikes[offer.id] ? "primary" : "default"}
                      aria-label="me sirve"
                      disabled={!userAuthenticated}
                    >
                      <ThumbUpAltIcon />
                      <Typography sx={{ fontSize: "12px" }}>
                        Me Sirve
                      </Typography>
                    </IconButton>
                    <Typography
                      color="text.secondary"
                      sx={{ ml: 1, fontSize: "12px" }}
                    >
                      - Ha {offer.helpedCount || 0} personas les sirvio esta
                      oferta
                    </Typography>
                  </Box>
                </Box>

                {/* Right column for content */}
                <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                  <Typography style={styles.title}>{offer.product}</Typography>
                  <Typography style={styles.description}>
                    {offer.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href={`http://${offer.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ width: "100%", mt: 1, mb: 1 }}
                  >
                    Comprar en {getDomainFromUrl(offer.url)}
                  </Button>
                  <Typography style={styles.offer}>
                    Descuento: {offer.offer}%
                  </Typography>
                  <CopyToClipboardComponent textToCopy={offer.cupon} />
                  <Typography style={styles.created}>
                    Fecha de publicación: {formatDate(offer.createdAt)}
                  </Typography>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => handleFollowClick(offer.uid)}
                      disabled={loading}
                    >
                      {isCompanyFollowed(offer.uid)
                        ? "Dejar de seguir"
                        : "Seguir"}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Preguntas:</Typography>
                <List>
                  {questionsAndAnswers[offer.id]?.map((item, index) => (
                    <Collapse
                      key={item.id}
                      in={index === 0 || showMoreQuestions[offer.id]}
                    >
                      <ListItem alignItems="flex-start">
                        <Paper elevation={3} sx={{ p: 1, width: "100%" }}>
                          <Box display="flex" alignItems="center" mb={2}>
                            <Avatar
                              src={item.profileImageUrl}
                              alt={item.displayName}
                              sx={{
                                width: 32,
                                height: 32,
                                border: "2px solid #312783",
                                mr: 1,
                              }}
                            />
                            <Typography variant="subtitle1" sx={{ ml: 2 }}>
                              <strong>{item.displayName}</strong> preguntó:
                            </Typography>
                          </Box>
                          <Typography paragraph>{item.question}</Typography>
                          <Divider sx={{ my: 2 }} />
                          {item.answers.map((answer) => (
                            <Box key={answer.id} sx={{ ml: 2, mt: 1 }}>
                              <Box display="flex" alignItems="center" mb={1}>
                                <Avatar
                                  src={answer.profileImageUrl}
                                  alt={answer.displayName}
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    border: "2px solid #312783",
                                    mr: 1,
                                  }}
                                />
                                <Typography variant="subtitle1" sx={{ ml: 2 }}>
                                  <strong>{answer.displayName}</strong>{" "}
                                  respondió:
                                </Typography>
                              </Box>
                              <Typography paragraph>{answer.answer}</Typography>
                            </Box>
                          ))}
                          {auth.currentUser?.uid === offer.uid &&
                            !item.answers.length && (
                              <Box sx={{ display: "flex", mt: 1 }}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  placeholder="Escribe una respuesta..."
                                  value={newAnswer[item.id] || ""}
                                  onChange={(e) =>
                                    setNewAnswer((prevAnswers) => ({
                                      ...prevAnswers,
                                      [item.id]: e.target.value,
                                    }))
                                  }
                                  inputRef={(ref) =>
                                    (answerInputRef.current[item.id] = ref)
                                  }
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{ ml: 1 }}
                                  onClick={() =>
                                    handleAnswerSubmit(item.id, offer.id)
                                  }
                                >
                                  Responder
                                </Button>
                              </Box>
                            )}
                        </Paper>
                      </ListItem>
                    </Collapse>
                  ))}
                </List>
                {questions[offer.id]?.length > 1 && (
                  <Typography
                    onClick={() =>
                      setShowMoreQuestions((prev) => ({
                        ...prev,
                        [offer.id]: !prev[offer.id],
                      }))
                    }
                    sx={{
                      mt: 1,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {showMoreQuestions[offer.id]
                      ? "Mostrar menos preguntas"
                      : "Mostrar más preguntas"}
                  </Typography>
                )}

                <Box sx={{ display: "flex", mt: 1 }}>
                  <TextField
                    fullWidth
                    placeholder="Escribe una pregunta..."
                    value={newQuestion}
                    onChange={(e) => setNewQuestion(e.target.value)}
                  />
                  <Button
                    sx={{ ml: 1 }}
                    variant="contained"
                    onClick={() => handleQuestionSubmit(offer.id)}
                  >
                    Preguntar
                  </Button>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Container>
    );
  };

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/home" color="black">
              <HomeIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Inicio
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/members" color="black">
              <SearchIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Buscar
            </Typography>
          </div>
          <div>{icon}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/notifications" color="black">
              <NotificationIcon hasNewNotifications={hasNewNotifications} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Notificaciones
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/profile" color="black">
              <AccountCircleIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Perfíl
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Notiline"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <AttachMoneyIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Ofertas
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/reference" color="black">
                <AccessTimeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Referencias
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/events" color="black">
                <EventIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Eventos
              </Typography>
            </div>
          </Toolbar>
          <Toolbar
            sx={{
              ...styles.search,
              padding: "0px 16px",
              borderRadius: "4px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <input
              type="text"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              style={{
                ...styles.inputS,
                width: "80%",
                padding: "8px 12px",
                borderRadius: "20px",
                border: "1px solid #e0e0e0",
                fontSize: "14px",
                transition: "border-color 0.3s",
                "&:focus": {
                  outline: "none",
                  borderColor: "#3f51b5",
                },
              }}
              placeholder="Buscar por negocio..."
            />
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {busqueda.trim() !== "" && (
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                {resultado.length > 0
                  ? `Resultados de búsqueda para "${busqueda}" (${resultado.length})`
                  : `No se encontraron resultados para "${busqueda}"`}
              </Typography>
            )}
            {busqueda.trim() !== "" ? (
              resultado.length > 0 ? (
                renderOfferList(resultado)
              ) : (
                <Typography>
                  No se encontraron ofertas que coincidan con tu búsqueda.
                </Typography>
              )
            ) : filteredOffers.length > 0 ? (
              renderOfferList(filteredOffers)
            ) : (
              renderOfferList(offers)
            )}
          </>
        )}
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen
        >
          <DialogTitle id="image-modal-title">
            Imágenes del producto
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {openImageModal && (
                <Box style={styles.imageModalContent}>
                  {modalImages && modalImages.length > 0 ? (
                    <CardMedia
                      component="img"
                      image={modalImages[currentImageIndex]}
                      alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                      style={styles.imageModalImage}
                    />
                  ) : (
                    <Typography>No hay imágenes disponibles</Typography>
                  )}
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Container>
  );
};

export default Home;
