import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar,
} from "@mui/material";
import {
  getDoc,
  doc,
} from "firebase/firestore";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "react-calendar/dist/Calendar.css";
import {
  AccountCircle as AccountCircleIcon,
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  formatDate,
  useListenForNewPostsAndEvents,
  fetchFollowing,
  fetchEvents,
  fetchAttendees,
  handleAttend,
  handleFollow,
} from "./eventsUtils";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [events, setEvents] = useState([]);
  const [resultado, setResultados] = useState([]);
  const [following, setFollowing] = useState([]);
  const [attendees, setAttendees] = useState({});
  const [attendeeProfiles, setAttendeeProfiles] = useState({});
  const [busqueda, setBusqueda] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);
  const [icon, setIcon] = useState(null);
  const navigate = useNavigate();

  useListenForNewPostsAndEvents();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchIcon = async () => {
      const result = await getIconForUserType();
      setIcon(result);
    };

    fetchIcon();
  });

  const getIconForUserType = async () => {
    const { uid } = auth.currentUser || {};
    if (!uid) {
      return null;
    }
    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const userType = userData.userType;

      if (userType === "empresa") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/publishEvents">
              <AddCircleIcon sx={{ color: "#312783" }} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Publicar
            </Typography>
          </div>
        );
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    const unsubscribe = subscribeToNotifications(setHasNewNotifications);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const unsubscribe = fetchEvents(setLoading, setEvents);
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    fetchFollowing(setFollowing, setLoading);
  }, []);

  useEffect(() => {
    fetchAttendees(events, setAttendees, setAttendeeProfiles);
  }, [events]);


  const handleAttendEvent = useCallback(
    async (eventId) => {
      await handleAttend(eventId);
      fetchAttendees(events, setAttendees, setAttendeeProfiles);
    },
    [events]
  );

  const handleFollowCompany = useCallback(
    async (companyId) => {
      await handleFollow(companyId, following, setFollowing, setLoading);
    },
    [following]
  );

  const handleOpenImageModal = useCallback((images) => {
    setModalImages(images);
    setOpenImageModal(true);
  }, []);

  const handleCloseImageModal = useCallback(() => {
    setOpenImageModal(false);
  }, []);

  const handleNextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  }, [modalImages.length]);

  const handlePreviousImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  }, [modalImages.length]);

  const handleClickPerfil = useCallback(
    (event) => {
      if (event) {
        navigate(`/perfil/${event.uid}`);
      }
    },
    [navigate]
  );

  const isCompanyFollowed = useCallback(
    (companyId) => following.includes(companyId),
    [following]
  );

  const buscar = useCallback(() => {
    if (!Array.isArray(events)) {
      console.error("events no es un array:", events);
      return;
    }
    const valorBusqueda = busqueda.toLowerCase().trim();
    const resultadosFiltrados = events.filter(
      (event) =>
        event.name.toLowerCase().includes(valorBusqueda) ||
        (event.title && event.title.toLowerCase().includes(valorBusqueda))
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, events]);

  useEffect(() => {
    buscar();
  }, [busqueda, events, buscar]);

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "99%",
      border: "0.5px solid #ccc",
      margin: "25px auto",
      boxShadow: "0px 0px 5px 0px black",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "15px",
      marginTop: "15px",
      textDecoration: "underline",
    },
    title: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "15px",
    },
    description: {
      fontWeight: "300",
      fontSize: "16px",
      marginBottom: "15px",
    },
    event: {
      color: "black",
      fontWeight: "700",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
      fontWeight: "400",
      marginBottom: "15px",
    },
    created: {
      fontWeight: "300",
      marginTop: "15px",
      marginBottom: "15px",
      fontSize: "12px",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },

    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
    imagePreview: {
      width: "100%",
      height: "200px",
      objectFit: "cover",
      borderRadius: "10px",
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      justifyContent: "space-around",
    },
  };

  const renderEventList = useMemo(() => {
    const eventsToRender = busqueda.trim() !== "" ? resultado : events;
    return eventsToRender.map((event) => (
      <ListItem key={event.id} style={styles.listItem}>
        <Box style={styles.contUser}>
          <CardMedia
            component="img"
            image={event.image}
            title="Perfil"
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              marginRight: 16,
              border: "2.5px solid #312783",
            }}
          />
          <Typography
            onClick={() => handleClickPerfil(event)}
            style={styles.name}
          >
            {event.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              width: { xs: "100%", md: "20%" },
              alignItems: { xs: "center", md: "flex-start" },
              mb: { xs: 2, md: 0 },
            }}
          >
            {event.images && event.images.length > 0 ? (
              <Box style={styles.imageContainer}>
                <CardMedia
                  component="img"
                  image={event.images[0]}
                  alt={`Imagen principal de ${event.name}`}
                  style={styles.mainImage}
                />
                {event.images.length > 1 && (
                  <Box
                    style={styles.additionalImagesOverlay}
                    onClick={() => handleOpenImageModal(event.images)}
                  >
                    <Typography style={styles.additionalImagesCount}>
                      +{event.images.length - 1}
                    </Typography>
                    <Typography>Ver más</Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box style={styles.noImage}>
                <Typography>No hay imágenes disponibles</Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "30px",
              width: { xs: "90%", md: "70%" },
            }}
          >
            <Typography style={styles.title}>{event.title}</Typography>
            <Typography style={styles.description}>
              {event.description}
            </Typography>
            <Typography style={styles.event}>
              Tipo de evento: {event.eventType}
            </Typography>
            <Typography style={styles.date}>
              Fecha: {formatDate(event.date)}
            </Typography>
            <Typography style={styles.created}>
              Fecha de publicación: {formatDate(event.createdAt)}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography style={styles.description}>Asistirán:</Typography>
              {attendees[event.id]?.slice(0, 3).map((attendeeId) => (
                <Avatar
                  key={attendeeId}
                  src={attendeeProfiles[attendeeId]?.profileImageUrl || ""}
                  alt="Avatar"
                  sx={{
                    width: 24,
                    height: 24,
                    mr: "1px",
                    border: "1px solid #312783",
                    borderRadius: "50%",
                    mb: 2,
                    ml: 2,
                  }}
                />
              ))}
              {attendees[event.id]?.length > 3 && (
                <Typography variant="caption">
                  +{attendees[event.id].length - 3} más
                </Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => handleAttendEvent(event.id)}
              disabled={loading}
            >
              {attendees[event.id]?.includes(auth.currentUser?.uid)
                ? "No asistiré"
                : "Asistiré"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => handleFollowCompany(event.uid)}
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {isCompanyFollowed(event.uid) ? "Dejar de seguir" : "Seguir"}
            </Button>
          </Box>
        </Box>
      </ListItem>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resultado,
    events,
    busqueda,
    attendees,
    attendeeProfiles,
    handleAttendEvent,
    handleFollowCompany,
    isCompanyFollowed,
    handleClickPerfil,
    handleOpenImageModal,
  ]);

  <Container sx={{ mt: 29, mb: 10 }}>
    <div>
      {loading ? (
        <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {busqueda.trim() !== "" && (
            <Typography variant="h6" style={{ marginBottom: "20px" }}>
              {resultado.length > 0
                ? `Resultados de búsqueda para "${busqueda}" (${resultado.length})`
                : `No se encontraron resultados para "${busqueda}"`}
            </Typography>
          )}
          <List>
            {renderEventList.length > 0 ? (
              renderEventList
            ) : (
              <Typography>
                {busqueda.trim() !== ""
                  ? "No se encontraron eventos que coincidan con tu búsqueda."
                  : "No hay eventos disponibles"}
              </Typography>
            )}
          </List>
        </>
      )}
    </div>
    <Dialog
      open={openImageModal}
      onClose={handleCloseImageModal}
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-description"
      fullScreen
    >
      <DialogTitle id="image-modal-title">Imágenes del Evento</DialogTitle>
      <DialogContent>
        <DialogContentText id="image-modal-description">
          {openImageModal && (
            <Box style={styles.imageModalContent}>
              {modalImages && modalImages.length > 0 ? (
                <CardMedia
                  component="img"
                  image={modalImages[currentImageIndex]}
                  alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                  style={styles.imageModalImage}
                />
              ) : (
                <Typography>No hay imágenes disponibles</Typography>
              )}
              <Box style={styles.imageModalNavigation}>
                <IconButton onClick={handlePreviousImage}>
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton onClick={handleNextImage}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseImageModal}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  </Container>;

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/home" color="black">
              <HomeIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Inicio
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/members" color="black">
              <SearchIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Buscar
            </Typography>
          </div>
          <div>{icon}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/notifications" color="black">
              <NotificationIcon hasNewNotifications={hasNewNotifications} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Notificaciones
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/profile" color="black">
              <AccountCircleIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Perfíl
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Notiline"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <AttachMoneyIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Ofertas
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/reference" color="black">
                <AccessTimeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Referencias
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/events" color="black">
                <EventIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Eventos
              </Typography>
            </div>
          </Toolbar>
          <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
            <Toolbar
              sx={{
                ...styles.search,
                padding: "0px 16px",
                borderRadius: "4px",
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
              }}
            >
              <input
                type="text"
                value={busqueda}
                onChange={(e) => setBusqueda(e.target.value)}
                style={{
                  ...styles.inputS,
                  width: "80%",
                  padding: "8px 12px",
                  borderRadius: "20px",
                  border: "1px solid #e0e0e0",
                  fontSize: "14px",
                  transition: "border-color 0.3s",
                  "&:focus": {
                    outline: "none",
                    borderColor: "#3f51b5",
                  },
                }}
                placeholder="Buscar por negocio o título del evento..."
              />
            </Toolbar>
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <List>
              {renderEventList.length > 0 ? (
                renderEventList
              ) : (
                <Typography>No hay eventos disponibles</Typography>
              )}
            </List>
          )}
        </div>
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen
        >
          <DialogTitle id="image-modal-title">Imágenes del Evento</DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {openImageModal && (
                <Box style={styles.imageModalContent}>
                  {modalImages && modalImages.length > 0 ? (
                    <CardMedia
                      component="img"
                      image={modalImages[currentImageIndex]}
                      alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                      style={styles.imageModalImage}
                    />
                  ) : (
                    <Typography>No hay imágenes disponibles</Typography>
                  )}
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Container>
  );
};

export default Events;
